var $statusInterval;

//Initialize tooltips
$('#installer_tabs > li a[title]').tooltip();

//Wizard
$('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
	var $target = $(e.target);

	if ($target.hasClass('disabled')) {
		return false;
	}
});

$(".next-step").click(function (e) {
	if($(this).hasClass('disabled') || $(this).attr('disabled')) return false;

	e.preventDefault();
	var $active = $('.wizard .nav-tabs .nav-item .active');
	var $activeli = $active.parent("li");
	var $step = $(this).data('step');
	var $data = $('#installer_' + $step).getFormData();
	var $errorDIV = $('#installer_' + $step + '_error');
	$data.step = $step;

	if($step == 'mysql' || $step == 'admin') {
		$data.tables = $('#createtables').is(':checked') ? 'yes' : 'no';
	}

	$.ajax({
		url: 'install.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		beforeSend: function () {
			$('#fullLoading').removeClass('hidden');
		},
		success: function (data) {
			$('#fullLoading').addClass('hidden');

			if (data.status === 'success') {
				$($activeli).find('a[data-toggle="tab"]').addClass("success");

				if(data.step !== undefined) {
					$($activeli).parent('ul').find('a[aria-controls="' + data.step + '"]').removeClass("disabled");
					$($activeli).parent('ul').find('a[aria-controls="' + data.step + '"]').click();
				} else {
					$($activeli).next().find('a[data-toggle="tab"]').removeClass("disabled");
					$($activeli).next().find('a[data-toggle="tab"]').click();
				}

			} else {
				$errorDIV.removeClass("hidden").genAlert('danger', data.message);
			}
		}
	});
});

$(".prev-step").click(function (e) {
	var $active = $('.wizard .nav-tabs .nav-item .active');
	var $activeli = $active.parent("li");

	$($activeli).prev().find('a[data-toggle="tab"]').removeClass("success disabled");
	$($activeli).prev().find('a[data-toggle="tab"]').click();
});

$("#installer_install").click(function(e) {
	e.preventDefault();

	var $active = $('.wizard .nav-tabs .nav-item .active');
	var $activeli = $active.parent("li");
	var $output = $('#installer_output');
	var $data = {};

	if($('#createtables').is(':checked')) {
		$data = Object.assign($('#installer_admin').getFormData(), $('#installer_mysql').getFormData());
		$data.tables = $('#createtables').is(':checked') ? 'yes' : 'no';
	}

	$data.step = 'install';

	$.ajax({
		url: 'install.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		beforeSend: function() {
			$('#installer_install').prop('disabled', true).addClass('disabled').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Installing...');
		},
		success: function(res) {
			$output.append('<div class="alert alert-info" role="alert">Installation stated</div>');

			res.progress.forEach(function (elmt) {
				$output.append('<div class="alert alert-' + elmt.type + '" role="alert">' + elmt.message + '</div>');
			});

			if (res.status === 'success') {
				$($activeli).find('a[data-toggle="tab"]').addClass("success");
				$('#btn_step5').removeClass('disabled').removeAttr('disabled');
				$('#staticEmail').val($('#adminuser').val());
				$('#installer_install').fadeOut();

				if(res.tables === 'yes')
					$('#install_success_tables').removeClass('hidden');
				else
					$('#install_success').removeClass('hidden');

				if (res.step !== undefined) {
					$($activeli).parent('ul').find('a[aria-controls="' + res.step + '"]').removeClass("disabled");
				} else {
					$($activeli).next().find('a[data-toggle="tab"]').removeClass("disabled");
				}

			} else {
				$errorDIV.removeClass("hidden").genAlert('danger', res.message);
			}

			/*installStatusUpdate();

			$statusInterval = setInterval(function() {
				installStatusUpdate();
			}, 500);*/
		}
	});
});

function installStatusUpdate() {
	var $active = $('.wizard .nav-tabs .nav-item .active');
	var $activeli = $active.parent("li");
	var $output = $('#installer_output');
	var $data = {};

	$data.fetch_progress = true;

	$.ajax({
		url: 'install.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		success: function (res) {
			$output.append('<div class="alert alert-' + res.type + '" role="alert">' + res.message + '</div>');

			if(res.status !== undefined) {
				if (res.status === 'success') {
					clearInterval($statusInterval);
					$($activeli).find('a[data-toggle="tab"]').addClass("success");

					if (res.step !== undefined) {
						$($activeli).parent('ul').find('a[aria-controls="' + res.step + '"]').removeClass("disabled");
						$($activeli).parent('ul').find('a[aria-controls="' + res.step + '"]').click();
					} else {
						$($activeli).next().find('a[data-toggle="tab"]').removeClass("disabled");
						$($activeli).next().find('a[data-toggle="tab"]').click();
					}

				} else {
					$errorDIV.removeClass("hidden").genAlert('danger', res.message);
				}
			}
		}
	});
}