$('#botlist').DataTable({
	"info": false,
	"paging": false,
	"columnDefs": [
		{"orderable": false, "targets": [0, 5]}
	],
	"order": [[1, "asc"]]
});

var $botlist_admin = $('#botlist_admin').DataTable({
	"info": false,
	"paging": false,
	"columnDefs": [
		{"orderable": false, "targets": [0, 6]}
	],
	"order": [[1, "asc"]]
});

var $userlist = $('#userlist').DataTable({
	"info": false,
	"paging": false,
	"columnDefs": [
		{"orderable": false, "targets": [0, 6]}
	],
	"order": [[1, "asc"]]
});

$('#userlist_search').on('keyup', function() {
	$userlist.search($(this).val()).draw();
});

$('#userlist_search_btn').click(function() {
	$userlist.search($('#userlist_search').val()).draw();
});

$('#botlist_admin_search').on('keyup', function() {
	$botlist_admin.search($(this).val()).draw();
});

$('#botlist_admin_search_btn').click(function() {
	$userlist.search($('#botlist_admin_search').val()).draw();
});

$('[data-toggle="tooltip"]').tooltip();
$('[data-extra="tooltip"]').tooltip();
$('[data-toggle="popover"]').popover();
$('[data-extra="popover"]').popover();
