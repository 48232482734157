var $tfamodal = $('#modal_TFA').modal({show: false});
var $usersettings_tfamodal = $('#modal_usersettings_tfa_confirm').modal({show: false});

$('#userssettings_twofactor_regen').click(function() {
	var $data = {
		'mode': 'users',
		'command': 'qrregen'
	};

	$.ajax({
		url: 'ajax.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		beforeSend: function() {
			$('#fullLoading').removeClass('hidden');
		},
		success: function(data) {
			$('#fullLoading').addClass('hidden');
			$.notify(data.status, data.message);

			if(data.status == 'success') {
				$('#userssettings_twofactor_qrcode_img').attr('src', data.qrcode);
				$('#userssettings_twofactor_code').html(data.code);
				$('#userssettings_twofactor_qrcode').html(data.code);
			}
		}
	});
});

$('#userssettings_save').click(function() {
	var $data = {
		'mode': 'users',
		'command': 'settings'
	};
	var $form = $('#userssettings_form').getFormData();
	var $field = $('#userssettings_twofactor_activstat');

	$data = Object.assign($data, $form);

	if($('#userssettings_current_activstat').val() !== $field.val()) {
		$usersettings_tfamodal.modal('show');
	} else {
		$.ajax({
			url: 'ajax.php',
			cache: false,
			data: $data,
			dataType: 'json',
			method: 'POST',
			beforeSend: function() {
				$('#fullLoading').removeClass('hidden');
			},
			success: function(data) {
				$('#fullLoading').addClass('hidden');
				$.notify(data.status, data.message);
			}
		});
	}
});

$('#usersettings_tfa_confirm_confirm').click(function() {
	var $data = {
		'mode': 'users',
		'command': 'settings',
		'second': 'tfa_check',
		'userssettings_twofactor_authencode': $('#userssettings_twofactor_authencode').val()
	};
	var $form = $('#userssettings_form').getFormData();

	$data = Object.assign($data, $form);

	$.ajax({
		url: 'ajax.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		beforeSend: function() {
			$('#fullLoading').removeClass('hidden');
		},
		success: function(data) {
			$('#fullLoading').addClass('hidden');

			if(data.status == 'tfa_check') {
				$.notify('error', data.message);
				$usersettings_tfamodal.modal('show');
			} else {
				$usersettings_tfamodal.modal('hide');
				$.notify(data.status, data.message);
			}
		}
	});
});

$('#auth_login_form').submit(function (e) {
	e.preventDefault();

	var $data = {
		'mode': 'auth',
		'command': 'login'
	};

	$data = Object.assign($data, $(this).getFormData());

	$.ajax({
		url: 'ajax.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		beforeSend: function() {
			$('#auth_login_form').fadeOut('slow');
			$('#auth_login_loading').fadeIn('slow');
		},
		success: function(data) {
			if(data.status == 'tfa_check') {
				$tfamodal.modal('show');
				$('#auth_login_password').attr('readonly', 'readonly');
				$('#auth_login_name').attr('readonly', 'readonly');
				$('#auth_login_authencode').focus();
			} else if(data.status == 'success') {
				$('#auth_login_password').attr('readonly', 'readonly');
				$('#auth_login_name').attr('readonly', 'readonly');
				$('#auth_login_message').removeClass('alert-danger').addClass('alert-success').html(data.message).fadeIn('slow');
				setTimeout(function() {
					window.document.location.reload();
				}, 5000);
			} else {
				$('#auth_login_form').fadeIn('slow');
				$('#auth_login_loading').fadeOut('slow');
				$('#auth_login_password').val('').addClass('is-invalid');
				$('#auth_login_name').addClass('is-invalid');
				$('#auth_login_form').shake();
				$('#auth_login_message').removeClass('alert-success').addClass('alert-danger').html(data.message).fadeIn('slow');
			}
		}
	});
});

$('#auth_tfa_form').submit(function(e) {
	e.preventDefault();

	var $data = {
		'mode': 'auth',
		'command': 'tfa'
	};

	$data = Object.assign($data, $(this).getFormData());

	$.ajax({
		url: 'ajax.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		beforeSend: function() {
			$('#auth_login_form').fadeOut('slow');
			$('#auth_login_loading').fadeIn('slow');
		},
		success: function(data) {
			$tfamodal.modal('hide');

			if(data.status == 'success') {
				$('#auth_login_message').removeClass('alert-danger').addClass('alert-success').html(data.message).fadeIn('slow');
				setTimeout(function() {
					window.document.location.reload();
				}, 5000);
			} else {
				$('#auth_login_form').fadeIn('slow');
				$('#auth_login_loading').fadeOut('slow');
				$('#auth_login_message').removeClass('alert-success').addClass('alert-danger').html(data.message).fadeIn('slow');
				$('#auth_login_password').removeAttr('readonly');
				$('#auth_login_name').removeAttr('readonly');
				$('#auth_login_password').val('');
				$('#auth_login_authencode').val('');
			}
		}
	});
});

$('#auth_logout_btn').click(function(e) {
	e.preventDefault();

	var $data = {
		'mode': 'auth',
		'command': 'logout'
	};

	$.ajax({
		url: 'ajax.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		beforeSend: function() {
			$('#fullLoading').removeClass('hidden');
		},
		success: function(data) {
			$.notify(data.status, data.message);
			setTimeout(function() {
				window.document.location.reload();
			}, 6000);
		}
	});
});

$('#auth_login_password').keyup(function() {
	if($(this).hasClass('is-invalid')) $(this).removeClass('is-invalid');
	if($(this).hasClass('is-valid')) $(this).removeClass('is-valid');

	$('#auth_login_message').fadeOut('slow');
});

$('#auth_login_name').keyup(function() {
	if($(this).hasClass('is-invalid')) $(this).removeClass('is-invalid');
	if($(this).hasClass('is-valid')) $(this).removeClass('is-valid');

	$('#auth_login_message').fadeOut('slow');
});

$('#usersettings_tfa_confirm_cancel').click(function() {
	$('#userssettings_twofactor_authencode').val('');
	$usersettings_tfamodal.modal('hide');
});